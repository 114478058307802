@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'), url(./fonts/poppins_regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('PoppinsSemiBold'), url(./fonts/poppins_semi_bold.ttf) format('truetype');
}

body {
  font-family: 'PoppinsRegular', 'PoppinsSemiBold', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow: hidden;
}

code {
  font-family: 'PoppinsRegular', 'PoppinsSemiBold', sans-serif;
}

*:focus {
  outline: none;
}

.react-tiny-popover-container {
  z-index: 1000;
}

.modal-base {
  top: 15vh;
  position: relative;
  border: 1px solid #d9deea;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(38, 26, 89, 0.3);
  margin: auto;
  padding: 0;
  width: 33vw;
  max-height: 75vh;
  opacity: 0;
  -webkit-transition: opactity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.modal-base-wide {
  top: 15vh;
  position: relative;
  border: 1px solid #d9deea;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(38, 26, 89, 0.3);
  margin: auto;
  padding: 0;
  width: 39vw;
  max-height: 75vh;
  opacity: 0;
  -webkit-transition: opactity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.modal-base-narrow {
  top: 15vh;
  position: relative;
  border: 1px solid #d9deea;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(38, 26, 89, 0.3);
  margin: auto;
  padding: 0;
  width: 22vw;
  max-height: 75vh;
  opacity: 0;
  -webkit-transition: opactity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.fullscreen-modal-base {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: opactity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.fullscreen-modal-transparent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: opactity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

.modal-base_after-open {
  opacity: 1;
}

.modal-base_before-close {
  opacity: 0;
}

.overlay-base {
  z-index: 1006;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 26, 89, 0.3);
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.overlay-base_after-open {
  opacity: 1;
}

.overlay-base_before-close {
  opacity: 0;
}

.custom-json-pretty {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.ant-btn-primary {
  text-shadow: none;
}

.ant-btn-upload {
  padding-left: 16px;
  padding-right: 11px;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  background-color: #e1e9f8;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
  user-select: none;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue:hover {
  color: #fff;
}

.ReactCollapse--collapse {
  transition: height 100ms;
}

.rotate {
  animation: rotate-keyframes 0.5s forwards;
}

@keyframes rotate-keyframes {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-iteration-count: 1;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost-container {
  position: relative;
  height: 100%;
}

.scrollhost {
  overflow: auto;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.scroll-bar {
  width: 10px;
  height: 100%;
  right: 0;
  top: 0px;
  position: absolute;
  border-radius: 7px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
}

.scroll-thumb {
  width: 8px;
  height: 20px;
  margin-left: 1px;
  position: absolute;
  border-radius: 7px;
  opacity: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55);
}

.ant-spin-dot-item {
  background-color: #452d8c;
}

.ant-spin-text {
  color: #452d8c;
}

.ant-dropdown-menu {
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.5);
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.5);
}

.table-border {
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  -moz-border-image: url(assets/border.png) 5 5 5 5 repeat repeat;
  -webkit-border-image: url(assets/border.png) 5 5 5 5 repeat repeat;
  -o-border-image: url(assets/border.png) 5 5 5 5 repeat repeat;
  border-image: url(assets/border.png) 5 5 5 5 repeat repeat;
}
