/*TODO: Replace with styled-components */

.outer-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button-bar {
  margin-bottom: 1rem;
}

.grid-wrapper {
  flex: 1 1 auto;
}

.ag-root-wrapper {
  border: solid 0px;
  border-color: var(--ag-border-color, #fff) !important;
}
.ag-header-cell {
  padding: 0 !important;
}
.ag-header-cell-text {
  color: #b2aec1;
  font-family: 'PoppinsRegular', 'PoppinsSemiBold', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.ag-header-container {
  background-color: white;
}

.ag-cell-value {
  color: #261a59;
  border: none !important;
  font-family: 'PoppinsRegular', 'PoppinsSemiBold', sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.ag-row-even:nth-child(1n),
.ag-row-even:nth-child(1n):hover {
  background: #f5f6fa;
  border: none;
}

.ag-row-odd:nth-child(2n),
.ag-row-odd:nth-child(2n):hover {
  background: transparent;
  border: none;
}

.ag-theme-alpine .ag-header {
  border: none;
}

.ag-cell {
  padding: 0 !important;
}
.ag-cell-value:hover {
  background: #e8e8fc;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 35px !important;
}

.ant-popover-inner {
  border-radius: 6px;
}

.ant-popover-inner-content,
.ant-popover-content {
  background: none;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover::after {
  display: none;
}
